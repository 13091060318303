import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
// import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hub/hero/hero'
import CardSlider from '../components/hub/cardSlider/cardSlider'
import SubscribeBar from '../components/subscribeBar/subscribeBar'

import * as styles from '../components/globals-v2.module.css'

export const query = graphql`
  query AudioTemplateQuery($id: String!) {
    audio: sanityAudio(id: { eq: $id }) {
        _type
        tags {
            title
            slug {
                current
            }
        }
        title
        slug {
            current
        }
        series {
            title
        }
        audioUrl {
          asset {
            url
          }
        }
        featureImage {
            asset {
                _id
                url
            }
        }
        backgroundImage {
          asset {
              _id
              url
          }
        }
        appleUrl
        spotifyUrl
        youTubeUrl
        metaTitle
        metaDescription
        metaKeywords
        metaImageFB {
          asset {
              _id
              url
          }
        }
        metaImageTW {
          asset {
              _id
              url
          }
        }
    }

    podcasts: allSanityAudio(limit: 12, sort: {order: DESC, fields: publishedAt}) {
        nodes {
            id
            _type
            tags {
              title
              slug {
                current
              }
            }
            title
            slug {
              current
            }
            series {
                title
            }
            audioUrl {
              asset {
                url
              }
            }
            featureImage {
              asset {
                _id
                url
              }
            }
            appleUrl
            spotifyUrl
            youTubeUrl
        }
    }

    youMayAlsoLike: allSanityVideo(limit: 5, sort: {order: DESC, fields: publishedAt}) {
        nodes {
            _type
            tags {
              title
              slug {
                current
              }
            }
            title
            slug {
              current
            }
            series {
              title
            }
            featureImage {
              asset {
                _id
                url
              }
            }
        }
    }
  }
`

const AudioTemplate = props => {
  const { data, errors } = props
  const audio = data && data.audio
  const podcasts = data.podcasts.nodes;
  const youMayAlsoLike = data.youMayAlsoLike.nodes;
  
  return (
    <Layout hideContactBar={true} hideAnnouncements={true} logoOverrideUrl="/start/" darkMode={true} version="2">
      {/* {errors && <SEO title='GraphQL Error' />}
      {landing && <SEO title={landing.title || 'Untitled'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )} */}

      <SEO title={audio.metaTitle || audio.title} description={audio.metaDescription} keywords={audio.metaKeywords} imageFB={audio.metaImageFB} imageTW={audio.metaImageTW} />

      <Hero type="audio" record={audio} />

      <div className={styles.backgroundControl}>
            <Container size="large">
                <section className={`podcasts`}>
                    <h6 style={{margin: "0 0 60px"}}>PODCASTS</h6>
                    <CardSlider type="audio" data={podcasts} duel={true} />
                </section>
            </Container>
        </div>

        <div className={styles.backgroundControl}>
            <Container size="large" class={styles.sectionPad}>
                <section className={styles.audioPosts}>
                    <h6>MORE AUDIO</h6>
                    <CardSlider type="simple" data={podcasts} settings={{slidesToShow: 3}} />
                </section>

                <section className={`videos`}>
                    <h6 style={{margin: "0 0 60px", textAlign: "center"}}>YOU MIGHT LIKE</h6>
                    <CardSlider type="visual" data={youMayAlsoLike} />
                </section>
            </Container>
        </div>

        <div className={styles.backgroundControlAlt}>
            <Container size="large">
                <SubscribeBar />
            </Container>
        </div>
    </Layout>
  )
}

export default AudioTemplate
