import React, { useMemo, useState } from 'react'
import axios from 'axios'

import * as styles from './video.module.css'

const Video = ({videoId, type = 'standard'}) => {
    const [script, setScript] = useState(null);

    const players = {
        'standard': 'NHPGCLVd',
        'teaser': 'ef3wiA8P',
        'hero': 'mh6rnlRF'
    }

    useMemo(() => {
        const options = {
            method: 'GET',
            url: `https://cdn.jwplayer.com/players/${videoId}-${players[type]}.html?v2`,
            headers: {Accept: 'application/json; charset=utf-8'}
        };
        
        axios.request(options).then(function (response) {
            setScript(response.data);
        }).catch(function (error) {
            console.error(error);
        });
    }, [videoId]);

    return (
        <div className={styles.video}>
            {/* <script type="text/javascript">
                {script}
            </script> */}
            <iframe srcDoc={script} allowFullScreen />
        </div>
    )
}

export default Video;