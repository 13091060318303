import React from "react";
// import Button from "../button/button";
import Icon from "../icons";

import * as styles from './shareLinks.module.css';

const ShareLinks = ({ isOpen, title }) => {
    const link = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <div className={styles.shareWrapper}>
            <div className={styles.share}>
                <span>SHARE</span>
            </div>
            <div className={`${styles.shareLinks} ${isOpen && styles.active}`}>
                <span className={styles.close}><Icon symbol="shareClose" /></span>
                <div className={styles.links}>
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${link}`} className={`${styles.icon} ${styles.facebook}`}><Icon symbol="shareFacebook" /></a>
                    <a href={`https://www.LinkedIn.com/shareArticle?mini=true&url=${link}&title=${title}&summary=&source=website`} className={`${styles.icon} ${styles.linkedin}`}><Icon symbol="shareLinkedin" /></a>
                    <a href={`https://twitter.com/intent/tweet?text=${link}`} className={`${styles.icon} ${styles.twitter}`}><Icon symbol="shareTwitter" /></a>
                    <a href={`mailto:?body=${link}`} className={`${styles.icon} ${styles.email}`}><Icon symbol="shareEmail" /></a>
                    {/* <a href="" className={`${styles.icon} ${styles.link}`}><Icon symbol="shareLink" /></a> */}
                </div>
                {/* <Button size="small">Subscribe</Button> */}
            </div>
        </div>
    )
}

export default ShareLinks;