import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby';
import Button from '../../button/button';
import Icon from '../../icons';
import Video from '../../video/video'

import * as styles from './hero.module.css'
import ShareLinks from '../../shareLinks/shareLinks';

const Hero = ({ type, record }) => {
    const [videoPlay, setVideoPlay] = useState(false);
    const [videoDisplay, setVideoDisplay] = useState('background');
    const [audioPlay, setAudioPlay] = useState(false);

    useEffect(() => {
        const handleScroll = event => {
            if (window.scrollY > 0 && videoDisplay === 'background' && videoPlay) {
                // console.log("change to float");
                setVideoDisplay('float');
            } else if ((window.scrollY === 0 && videoDisplay === 'float') || (!videoPlay && videoDisplay === 'float')) {
                // console.log("change to background");
                setVideoDisplay('background');
            }
            // console.log('window.scrollY', window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, [videoPlay, videoDisplay]);

    const clickEvent = () => {
        if (record._type === 'video') {
            setVideoPlay(!videoPlay);
        }

        if (record._type === 'audio') {
            if (typeof window !== 'undefined') {
                const heroAudio = document.getElementById('heroAudio');
                if (heroAudio.paused) {
                    heroAudio.play();
                    setAudioPlay(true);
                } else {
                    heroAudio.pause();
                    setAudioPlay(false);
                }
            }
        }
    }

    // const readTime = (content) => {
    //     const foundImages = (content.match(/<img/g) || []).length;
    //     const text = content.replace(/(<([^>]+)>)/gi, '');
    //     const foundWords = text.split(' ').length;

    //     let secs = (foundWords / 275) * 60;
    //     let addSecs = 12;
    //     for (let i = 0; i < foundImages; i++) {
    //       secs += addSecs;
    //       addSecs--;
    //       if (addSecs < 3) addSecs = 3;
    //     }
    //     const minutes = Math.ceil(secs / 60);

    //     return minutes;
    // }

    const listAuthors = (authors) => {
        const output = authors.map((author, index) => {
            let add = '';
            if (index > 0 && (index + 1) < authors.length) {
                add = ', ';
            } else if (index > 0 && (index + 1) === authors.length) {
                add = ' and ';
            }

            return (
                <React.Fragment key={index}>
                    {add}{author.team.length > 0 ?
                        <a href={`/our-people/${author.slug.current}/`}>{author.name}</a> :
                        <span>{author.name}</span>}
                </React.Fragment>
            )
        });

        return output;
    }

    const eyebrow = () => {
        if (type === 'written') return 'Article';
    }

    const backgroundImage = () => {
        if (record.backgroundImage && record.backgroundImage.asset) {
            return `${record.backgroundImage.asset.url}?q=10&format=auto&w=1280&h=960`;
        } else if (record.featureImage && record.featureImage.asset) {
            return `${record.featureImage.asset.url}?q=10&format=auto&w=1280&h=960`;
        } else return null;
    }

    return (
        <section className={`${styles.hero} ${styles[`type${type}`]} ${styles[`record${record._type}`]}`} style={{backgroundImage: `url(${backgroundImage()})`}} role="presentation">
            <div className={styles.content}>
                <div className={styles.titles}>
                    {'series' in record && record.series?.title && (<span className={styles.overline}>Series: {record.series.title}</span>)}
                    {type === 'main' ? (
                        <span className={styles.hJumbo}>{record.title}</span>
                    ) : (
                        <>
                            {!('series' in record) && (<span className={styles.overline}>{eyebrow()}</span>)}

                            {type === 'written' ? (
                                <>
                                    <span className={styles.hJumbo}>{record.title}</span>
                                    {record.tags && (
                                        <div className={styles.tags}>
                                            {record.tags.map((tag, tagIndex) => (
                                                <Link key={tagIndex} to={`/start/tag/${tag.slug.current}/`} className={styles.tag}>
                                                    {tag.title}
                                                </Link>
                                            ))}
                                        </div>
                                    )}
                                    <div className={styles.author}>By {listAuthors(record.author)}</div>
                                    <span className={styles.publishDate}>Published on {record.publishedAt}</span>
                                    {/* <span className={styles.readTime}>{readTime(record._rawContent)} Min. Read</span> */}
                                </>
                            ) : (
                                <h1>{record.title}</h1>
                            )}
                        </>
                    )}
                    {(type === 'main' && record._type === 'video') && (
                        <>
                            <Button onClick={() => clickEvent()}>Play <Icon symbol="play" /></Button>
                            {/* <div className={styles.videoBackground}>
                                <Video videoId={record.videoId} />
                            </div> */}
                        </>
                    )}
                    {(type === 'video' && record._type === 'video') && (
                        <div className={styles.videoDisplay}>
                            <div className={styles.videoView}>
                                <Video videoId={record.videoId} />
                                {/* <img src={record.splashScreen.asset.url} alt={record.title} /> */}
                                {/* <Icon symbol="playCircle" /> */}
                            </div>
                            <div className={styles.videoDetails}>
                                <div>
                                    {record.tags && (
                                        <div className={styles.tags}>
                                            {record.tags.map((tag, tagIndex) => (
                                                <Link key={tagIndex} to={`/start/tag/${tag.slug.current}/`} className={styles.tag}>
                                                    {tag.title}
                                                </Link>
                                            ))}
                                        </div>
                                    )}

                                    <div className={styles.footnote}>
                                        {record.footnote}
                                    </div>
                                </div>

                                <ShareLinks title={record.title} />
                            </div>
                        </div>
                    )}
                    {record._type === 'audio' && (
                        <>
                            {record.tags && (
                                <div className={styles.tags}>
                                    {record.tags.map((tag, tagIndex) => (
                                        <Link key={tagIndex} to={`/start/tag/${tag.slug.current}/`} className={styles.tag}>
                                            {tag.title}
                                        </Link>
                                    ))}
                                </div>
                            )}
                            <div className={styles.listenOn}>Listen on <Button level="secondary" size="small" link={record.spotifyUrl}>Spotify <Icon symbol="arrowIcon" /></Button> <Button level="secondary" size="small" link={record.appleUrl}>Apple <Icon symbol="arrowIcon" /></Button> or <Button level="secondary" size="small" link={record.youTubeUrl}>YouTube <Icon symbol="arrowIcon" /></Button></div>
                        </>
                    )}
                </div>
                {record._type === 'audio' && (
                    <div className={styles.audioCover} style={{backgroundImage: `url(${record.featureImage && `${record.featureImage.asset.url}?w=400&h=400&fit=crop&crop=center`})`}} onClick={() => clickEvent()}>
                        {audioPlay ? (
                            <Icon symbol="playCircle" />
                        ) : (
                            <Icon symbol="playCircle" />
                        )}
                        <div className={styles.audioPlayer}>
                            {record.audioUrl && (
                                <audio id="heroAudio" controls src={record.audioUrl.asset.url} />
                            )}
                        </div>
                    </div>
                )}
            </div>
            {(type === 'main' && record._type === 'video') && (
                <>
                    <div className={`${videoDisplay === 'background' && styles.videoBackground} ${videoDisplay === 'float' && styles.videoFloat} ${videoPlay && styles.activePlayer}`}>
                        {videoPlay && (<Video videoId={record.videoId} type='hero' />)}
                        {!videoPlay && (<Video videoId={record.videoId} type='teaser' />)}
                        <span className={styles.close} onClick={() => clickEvent()}><Icon symbol="close" /></span>
                    </div>
                </>
            )}

            <div className={styles.waveContain}>
                <div className={styles.wave}><Icon symbol="wave" /></div>
            </div>
        </section>
    )
}

export default Hero
